import React, { useState, useEffect } from 'react';
import { HeaderAlertProps } from './types';
import { BannerTopFadeOut, HeaderAlertLink, Wrapper } from './styles';
import { AnimatePresence } from 'framer-motion';

function HeaderAlert({ alerts }: HeaderAlertProps) {
  const [alertIndex, setAlertIndex] = useState(0);

  useEffect(() => {
    if (!alerts || alerts.length == 1) {
      return;
    }

    const interval = setInterval(() => {
      setAlertIndex((alertIndex) => {
        let nextIndex = alertIndex + 1;

        alertIndex = nextIndex >= alerts.length ? 0 : nextIndex;
        return alertIndex;
      });
    }, 6000);

    return () => clearInterval(interval);
  }, [alerts, setAlertIndex]);

  if (!alerts) {
    return null;
  }

  let alert = alerts[alertIndex];

  return (
    <Wrapper>
      <BannerTopFadeOut>
        <AnimatePresence initial={false} exitBeforeEnter>
          <HeaderAlertLink
            href={alert.alertName ? alert.alertName.url : ''}
            target={alert.alertName ? 'blank' : ''}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: 'easeIn', duration: 0.5 }}
            key={alert?.alertName?.title}
          >
            {alert?.alertName?.title}
          </HeaderAlertLink>
        </AnimatePresence>
      </BannerTopFadeOut>
    </Wrapper>
  );
}

export default HeaderAlert;
