import { UpdateCartShippingFragment } from 'utils/shipping/update-shipping-info';
import * as Sentry from '@sentry/nextjs';
import { ConfirmAddressProps, ConfirmAddressResponse } from './types';
import { throwServerSideError } from 'utils/helpers';
/**
 * Makes a POST call to /api/checkout/confirm-address
 * Intended for client-side use only
 * @param {ConfirmAddressProps} shippingAddress
 * @async
 */
export default async function checkoutConfirmAddress({
  cartId,
  shippingAddress,
}: ConfirmAddressProps): Promise<ConfirmAddressResponse> {
  throwServerSideError();

  try {
    const result: ConfirmAddressResponse = await fetch(
      '/api/checkout/confirm-address',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ cartId, shippingAddress }),
      },
    ).then((response) => response.json());

    if (result && result.cart) {
      UpdateCartShippingFragment(result.cart);
    }

    return result;
  } catch (e) {
    console.error(e);
    Sentry.captureException(e);
    throw new Error('Failed to save address, please try again.');
  }
}
