import styled from 'styled-components';
import theme from 'utils/theme';
import { motion } from 'framer-motion';
import { mobile } from 'utils/media';

export const Wrapper = styled.div`
  background: ${theme.colors.lightGreen};
  text-align: center;
  padding: 10px;
  font-size: 14px;
  z-index: ${theme.zIndexes.modals};
  position: relative;
  overflow: hidden;

  ${mobile} {
    font-size: 12px;
  }
`;

export const BannerTopFadeOut = styled.span`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const HeaderAlertLink = styled(motion.a)`
  width: 100%;
  color: ${theme.colors.black};
`;
