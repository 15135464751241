import React from 'react';
import { IconButton, IconLink } from 'components/global/Header/styles';
import { Basket as BasketIcon } from 'components/icons';
import { useGroupedActiveCart } from 'utils/commercetools/cart';
import { Wrapper, Badge } from './styles';

export default function Basket() {
  const activeCart = useGroupedActiveCart();

  const bagItems = activeCart.data?.lineItems.reduce(
    (acc, curr) => acc + curr.quantity,
    0,
  );

  return (
    <IconLink href="/basket" ariaLabel="Basket" data-testid="header-basket">
      <IconButton as="div">
        <Wrapper>
          {bagItems ? <Badge>{bagItems}</Badge> : null}
          <BasketIcon />
        </Wrapper>
      </IconButton>
    </IconLink>
  );
}
