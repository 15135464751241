import Container from 'components/layout/Container';
import styled from 'styled-components';
import { tablet } from 'utils/media';
import theme from 'utils/theme';

interface IWrapperProps {
  isOpen: boolean;
  cookieAccepted: boolean;
}

export const StyledContainer = styled(Container)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
});

export const CloseButton = styled.button({
  width: 16,
  height: 16,
  border: 0,
  padding: 0,
  background: 'none',
  cursor: 'pointer',
  float: 'right',
});

export const Wrapper = styled.div<IWrapperProps>(
  ({ isOpen, cookieAccepted }) => ({
    backgroundColor: theme.colors.pink,
    borderTopLeftRadius: '30px',
    borderTopRightRadius: '30px',
    bottom: 0,
    opacity: isOpen ? 1 : 0,
    left: 0,
    right: 0,
    margin: '0 auto',
    padding: `24px 24px ${cookieAccepted ? '70px' : '150px'} 24px`,
    position: 'fixed',
    transform: `translateY(${isOpen ? 0 : '100%'})`,
    transition: `all ${theme.transitionSpeeds.normal}s ease-out`,
    willChange: 'transform',
    width: '100%',
    maxWidth: 450,
    overflow: 'hidden',

    [tablet]: {
      position: 'absolute',
      borderRadius: '30px',
      padding: '35px 35px 40px 35px',
      top: 140,
      bottom: 'auto',
      transform: `translateY(${isOpen ? '24px' : '-100%'})`,
      width: '100%',
    },
  }),
);

export const FormWrapper = styled.div`
  padding: 15px;
`;

export const ButtonWrapper = styled.div`
  text-align: center;
`;

export const BackgroundWrapper = styled.div`
  position: absolute;
  z-index: -1;

  svg {
    position: absolute;
    top: 0;
    width: 500px;
    height: 394px;
  }

  svg:nth-child(1) {
    left: -290px;
    transform: rotate(21deg);
  }

  svg:nth-child(2) {
    right: -720px;
    transform: rotate(-75deg);
  }

  svg:nth-child(3) {
    left: -350px;
    top: -310px;
    transform: rotate(30deg);
  }
`;
