// Derived from https://github.com/sanity-io/next-sanity/blob/main/README.md

import React, { ReactNode } from 'react';
import styled from 'styled-components';

import {
  createClient,
  createImageUrlBuilder,
  createPortableTextComponent,
  createPreviewSubscriptionHook,
  createCurrentUserHook,
  ClientConfig,
} from 'next-sanity';
import BlockContent from '@sanity/block-content-to-react';
import { Heading1, Heading2, Heading3, TitleHeading } from 'components/text';
import {
  SanityBlock,
  SanityProductCarouselTab,
} from 'components/blocks/RenderBlocks';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  GetProductsForSkusDocument,
  GetProductsForSkusQuery,
  GetProductsForSkusQueryVariables,
} from 'generated/api/graphql';
import Link from 'components/Link';
import Button from 'components/Button';
import { mapGraphQLProductToInterface } from './commercetools/map';
import { ProductListingDataFragmentWithSelectedVariant } from './types';
import { fallbackApolloClient } from './apollo-client';
import { tablet } from './media';
import theme from './theme';

interface TempClientConfig extends ClientConfig {
  documentLimit?: number;
}

const config: TempClientConfig = {
  /**
   * Find your project ID and dataset in `sanity.json` in your studio project.
   * These are considered “public”, but you can use environment variables
   * if you want differ between local dev and production.
   *
   * https://nextjs.org/docs/basic-features/environment-variables
   * */
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET || 'headless',
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID || 'fpwrylru', // 'fpwrylru',
  /**
   * Set useCdn to `false` if your application require the freshest possible
   * data always (potentially slightly slower and a bit more expensive).
   * Authenticated request (like preview) will always bypass the CDN
   * */
  useCdn: true,
  apiVersion: '2021-03-25',
  documentLimit: 10000,
};

/**
 * Set up a helper function for generating Image URLs with only the asset reference data in your documents.
 * Read more: https://www.sanity.io/docs/image-url
 * */
export const urlFor = (source: any) =>
  createImageUrlBuilder(config).image(source);

// Set up the live preview subscription hook
export const usePreviewSubscription = createPreviewSubscriptionHook(config);

const LargeCenterText = styled.p`
  text-align: center;

  ${tablet} {
    font-size: 24px;
    line-height: 32px;
  }
`;

const LargeText = styled.p`
  text-align: left;

  ${tablet} {
    font-size: 24px;
    line-height: 32px;
  }
`;

// Set up Portable Text serialization
const CTARenderer = ({ node }: any) => {
  if (!node.url || !node.title) {
    return null;
  }

  return (
    <p style={{ margin: '2em 0', textAlign: 'center' }}>
      <Link href={node.url}>
        <Button as="span" variant="primary">
          {node.title}
        </Button>
      </Link>
    </p>
  );
};

const BlockRenderer = (props: any) => {
  const { style = 'normal' } = props.node;

  if (style === 'largeCenter') {
    return <LargeCenterText>{props.children}</LargeCenterText>;
  }

  if (style === 'large') {
    return <LargeText>{props.children}</LargeText>;
  }

  if (style === 'center') {
    return <p style={{ textAlign: 'center' }}>{props.children}</p>;
  }

  if (style === 'centerH1') {
    return (
      <Heading1 style={{ textAlign: 'center' }}>{props.children}</Heading1>
    );
  }

  if (style === 'centerTitleHeading') {
    return (
      <TitleHeading style={{ textAlign: 'center' }}>
        {props.children}
      </TitleHeading>
    );
  }

  if (/^h\d/.test(style)) {
    const level = style.replace(/[^\d]/g, '');
    if (level === '1') {
      return <Heading1>{props.children}</Heading1>;
    }
    if (level === '2') {
      return <Heading2>{props.children}</Heading2>;
    }
    if (level === '3') {
      return <Heading3>{props.children}</Heading3>;
    }
  }

  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block(props);
};

const markSerializers = {
  colorAnnotation({
    mark,
    children,
  }: {
    mark: { color: string };
    children: ReactNode;
  }) {
    return (
      <span
        style={{
          color: mark.color ? theme.colors[mark.color] : 'inherit',
        }}
      >
        {children}
      </span>
    );
  },
};

export const PortableText = createPortableTextComponent({
  ...config,
  // Serializers passed to @sanity/block-content-to-react
  // (https://github.com/sanity-io/block-content-to-react)
  serializers: {
    marks: markSerializers,
    types: { ctaBlock: CTARenderer, block: BlockRenderer },
  },
});

// Set up the client for fetching data in the getProps page functions
export const sanityClient = createClient(config);

// Helper function for using the current logged in user account
export const useCurrentUser = createCurrentUserHook(config);

interface MapProductsArg {
  sku: string;
}

export async function mapProducts(
  products: MapProductsArg[],
  client?: ApolloClient<NormalizedCacheObject>,
): Promise<Sproutl.ProductListing[]> {
  const apolloClient = fallbackApolloClient(client);

  if (products && products.length) {
    const productsSkus = products
      .map((product) => product && product.sku)
      .filter((val) => val);

    const commercetoolsProducts = await apolloClient.query<
      GetProductsForSkusQuery,
      GetProductsForSkusQueryVariables
    >({
      query: GetProductsForSkusDocument,
      variables: {
        productsSkus,
        limit: productsSkus.length,
      },
    });

    const flatVariants = commercetoolsProducts.data.products.results
      .flatMap((product) =>
        product.masterData.current?.allVariants.map((variant) => ({
          ...product.masterData.current,
          selectedVariant: variant,
        })),
      )
      .reduce((acc, curr) => {
        if (curr && curr.selectedVariant.sku) {
          acc[curr.selectedVariant.sku] = curr;
        }
        return acc;
      }, {} as Record<string, ProductListingDataFragmentWithSelectedVariant>);

    const mappedToSkus = productsSkus.reduce((acc, sku) => {
      if (flatVariants?.[sku]) {
        acc.push(flatVariants[sku]);
      }
      return acc;
    }, [] as ProductListingDataFragmentWithSelectedVariant[]);

    const mappedResult: Sproutl.ProductListing[] = mappedToSkus.map(
      mapGraphQLProductToInterface,
    );

    if (mappedResult) {
      return mappedResult;
    }
  }

  return [];
}

/**
 * Map products and return only products in stock
 */
async function mapProductsOnStock(
  ...args: Parameters<typeof mapProducts>
): ReturnType<typeof mapProducts> {
  const allProducts = await mapProducts(...args);
  return allProducts.filter(({ isOnStock }) => isOnStock);
}

async function mapProductTabs(
  tabs: SanityProductCarouselTab[],
  client?: ApolloClient<NormalizedCacheObject>,
) {
  const apolloClient = fallbackApolloClient(client);

  return Promise.all(
    tabs.map(async (tab) => {
      const products = await mapProductsOnStock(
        tab?.products || [],
        apolloClient,
      );

      return {
        ...tab,
        products,
      };
    }),
  );
}

export async function mapBlocks(
  blocks: SanityBlock[],
  client?: ApolloClient<NormalizedCacheObject>,
) {
  const apolloClient = fallbackApolloClient(client);

  return Promise.all(
    blocks?.map(async (block) => {
      if (block._type === 'productCarousel' && block.tabs) {
        const tabs = await mapProductTabs(block.tabs, apolloClient);

        return {
          ...block,
          tabs,
        };
      } else if (block._type === 'editSet') {
        const productsSkus = block?.products?.filter((sku) => sku);

        return {
          ...block,
          products: await mapProducts(productsSkus, apolloClient),
        };
      }

      return block;
    }),
  );
}

export function toPlainText(blocks: any[] = []) {
  return (
    blocks
      // loop through each block
      .map((block) => {
        // if it's not a text block with children,
        // return nothing
        if (block._type !== 'block' || !block.children) {
          return '';
        }
        // loop through the children spans, and join the
        // text strings
        return block.children.map((child: any) => child.text).join('');
      })
      // join the paragraphs leaving split by two linebreaks
      .join('\n\n')
  );
}
