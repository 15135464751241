import * as Sentry from '@sentry/nextjs';
import {
  ConfirmExpressOrderProps,
  ConfirmOrderResponse,
} from 'utils/checkout/types';
import { IGroupedOrder } from 'utils/commercetools/types';
import { throwServerSideError } from 'utils/helpers';

/**
 * Sends an API call to confirm the order when using express checkout
 * @async
 * @param {ConfirmExpressOrderProps} props
 * @returns {Promise<IGroupedOrder>} order
 */
export async function checkoutConfirmExpressOrder(
  props: ConfirmExpressOrderProps,
): Promise<IGroupedOrder> {
  throwServerSideError();

  try {
    const { order }: ConfirmOrderResponse = await fetch(
      '/api/checkout/confirm-express-order',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(props),
      },
    ).then((response) => response.json());

    if (!order || !order.id) {
      throw new Error('Checkout Confirm Express Order: Could not find order');
    }

    return order;
  } catch (e: any) {
    console.error(e);
    Sentry.captureException(e, {
      contexts: {
        response: e.response,
        request: e.request,
        errorMessage: e.response?.data?.error?.message,
      },
    });
    if (e.response?.data?.error?.message) {
      throw new Error(e.response?.data?.error?.message);
    }
    if (e.message) {
      throw new Error(e.message);
    }
    throw new Error('Failed to create order, please try again');
  }
}
