import styled from 'styled-components';
import { desktop } from 'utils/media';
import { BreakpointValues } from 'utils/types';

interface ResponsiveBoxProps {
  aspectRatio: number | BreakpointValues;
  rounded?: boolean;
  size?: 'small' | 'medium';
}

const stylesForAspectRatio = (aspectRatio: number): any => ({
  '::before': {
    display: 'block',
    content: "''",
    width: 1,
    marginLeft: -1,
    float: 'left',
    height: 0,
    paddingTop: `${aspectRatio * 100}%`,
  },
  '::after': {
    display: 'table',
    clear: 'both',
    content: "''",
  },
});

const roundedSizes = {
  small: {
    borderRadius: '20%',
  },
  medium: {
    borderRadius: 40,
    [desktop]: {
      borderRadius: 80,
    },
  },
};
const ResponsiveBox = styled.div<ResponsiveBoxProps>(
  ({ aspectRatio, rounded, size = 'medium' }) => ({
    position: 'relative',
    overflow: 'hidden',
    ...(rounded ? roundedSizes[size] : {}),
    ...(typeof aspectRatio === 'number'
      ? stylesForAspectRatio(aspectRatio as number)
      : Object.entries(aspectRatio)
          .map(([breakpoint, currAspectRatio]) => [
            breakpoint,
            stylesForAspectRatio(currAspectRatio),
          ])
          .reduce((obj, [key, val]) => {
            // eslint-disable-next-line no-param-reassign
            obj[key] = val;
            return obj;
          }, {} as { [key: string]: any })),
  }),
);

export default ResponsiveBox;
