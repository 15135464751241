import React from 'react';
import { IconPlainLink } from 'components/global/Header/styles';
import { Profile as ProfileIcon } from 'components/icons';
import { useUser } from '@auth0/nextjs-auth0';
import { Wrapper, Avatar, ProfileIconButton } from './styles';

const Profile = () => {
  const { user, isLoading } = useUser();

  return (
    <Wrapper>
      {isLoading ? null : user ? (
        <IconPlainLink href="/account" aria-label="My Account">
          <ProfileIconButton as="div">
            {user.picture ? <Avatar src={user.picture} /> : <ProfileIcon />}
          </ProfileIconButton>
        </IconPlainLink>
      ) : (
        <IconPlainLink href="/api/auth/login" aria-label="Log in">
          <ProfileIconButton as="div" data-testid="login">
            <ProfileIcon />
          </ProfileIconButton>
        </IconPlainLink>
      )}
    </Wrapper>
  );
};

export default Profile;
