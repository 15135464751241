import React from 'react';
import {
  ButtonEl,
  ChildrenWrapper,
  LoadingDot,
  LoadingWrapper,
} from './styles';
import { ButtonProps } from './types';

export default function Button({
  id,
  as,
  name,
  loading,
  onClick,
  children,
  disabled,
  selected,
  colorScheme,
  width = 'auto',
  type = 'button',
  variant = 'primary',
  'data-testid': dataTestId,
}: ButtonProps) {
  const defaultColorScheme = variant === 'primary' ? 'pineGreen' : 'black';

  return (
    <ButtonEl
      as={as}
      id={id}
      name={name}
      width={width}
      onClick={onClick}
      variant={variant}
      loading={loading}
      selected={selected}
      type={as ? null : type}
      data-testid={dataTestId}
      aria-selected={selected}
      disabled={loading || disabled}
      aria-disabled={loading || disabled}
      colorScheme={colorScheme || defaultColorScheme}
    >
      <LoadingWrapper>
        <LoadingDot />
        <LoadingDot />
        <LoadingDot />
      </LoadingWrapper>
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </ButtonEl>
  );
}
