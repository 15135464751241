import * as Sentry from '@sentry/nextjs';
import { CreateMerchantCartResponse, CreateMerchantCartItems } from './types';
import { throwServerSideError } from 'utils/helpers';
/**
 * Makes a POST call to /api/checkout/confirm-address
 * Intended for client-side use only
 * @param {CreateMerchantCartItems} shippingAddress
 * @async
 */
export default async function checkoutCreateMerchantCart(
  items: CreateMerchantCartItems[],
): Promise<CreateMerchantCartResponse> {
  throwServerSideError();

  try {
    const result: CreateMerchantCartResponse = await fetch(
      '/api/checkout/create-merchant-cart',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ items }),
      },
    ).then((response) => response.json());

    return result;
  } catch (e) {
    console.error(e);
    Sentry.captureException(e);
    throw new Error('Failed to save address, please try again.');
  }
}
